import { FC, memo, useCallback, useEffect, useState } from 'react';
import './listCard.scss';
import ListCardDefault from './ListCardDefault/ListCardDefault';
import ListCardSuccess from './ListCardSuccess/ListCardSuccess';
import { IListCardModalProps, TransactionObj } from './types';
import { Modal } from '@/shared/ui/Modal';

export const ListCardModal: FC<IListCardModalProps> = memo(
  ({
    getDataCard,
    open,
    setOpen,
    cardData,
    subjectId,
    typeModal,
    currentUserWallet,
    setisLoading,
    modalFromPage,
    getNFTCardActivities,
  }) => {
    const { listing } = cardData;
    const [listingPrice, setListingPrice] = useState<string>('');
    const [currency, setCurrency] = useState<ICurrency>('USDC');
    const [transactionStatus, setTransactionStatus] = useState('none');
    const [error, setError] = useState<undefined | string>(undefined);
    const [transaction, setTransaction] = useState<TransactionObj>({
      currency: '',
      price: '',
      transactionId: '',
      transactionUrl: '',
    });

    const handleModalClose = useCallback(() => {
      setOpen('listCard', { isOpen: false, typeModal: '' });
      setTransactionStatus('none');
      setListingPrice('');
    }, [setOpen]);

    const changeTransactionStatus = (status: string) => {
      setTransactionStatus(status);
    };

    useEffect(() => {
      if (typeModal === 'update-listing' && !!listing?.price)
        if (listing?.priceInfo && listing?.priceInfo?.splPrice) {
          const price = (
            parseInt(listing.priceInfo.splPrice.rawAmount) /
            10 ** listing.priceInfo.splPrice.decimals
          )
            .toFixed(2)
            .toString();
          setListingPrice(price);
          setCurrency('USDC');
        } else {
          setCurrency('SOL');
          setListingPrice(listing?.price);
        }
    }, [listing, typeModal]);

    useEffect(() => {
      if (error) handleModalClose();
    }, [error, handleModalClose]);
    return (
      <Modal
        isOpen={open}
        className='list-card-modal'
        onClose={handleModalClose}
      >
        {transactionStatus === 'resolved' ? (
          <ListCardSuccess
            getDataCard={getDataCard}
            cardData={cardData}
            handleModalClose={handleModalClose}
            typeModal={typeModal}
            transaction={transaction}
          />
        ) : (
          <ListCardDefault
            getDataCard={getDataCard}
            cardData={cardData}
            handleModalClose={handleModalClose}
            transactionStatus={transactionStatus}
            changeTransactionStatus={changeTransactionStatus}
            subjectId={subjectId}
            typeModal={typeModal}
            setisLoading={setisLoading}
            setError={setError}
            initialPrice={listing?.price || ''}
            setTransaction={setTransaction}
            currency={currency}
            setCurrency={setCurrency}
            currentUserWallet={currentUserWallet}
            modalFromPage={modalFromPage}
            listingPrice={listingPrice}
            setListingPrice={setListingPrice}
            getNFTCardActivities={getNFTCardActivities}
          />
        )}
      </Modal>
    );
  },
);
